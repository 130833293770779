<template>
    <!-- Componente reutilizable con checkbox de aviso de privacidad -->
    <div class="row mx-0">
        <div class="col-1 pt-4 text-center">
            <input type="checkbox" name="aviso" class="checkbox" v-model="aviso"  required/>
        </div>
        <div class="col-11 pt-4 pl-2">
            <label class="checkbox_label" for="aviso">
                <span class="checkbox_text">He leído el y acepto el <strong class="text-blue bold-text"><router-link to="/politica-privacidad" target="_blank">Aviso de Privacidad</router-link></strong>.</span>
            </label>
        </div>
    </div>
    <!-- Mensaje de error -->
    <p v-if="errorFlag" class="text-danger mt-1 mb-0 bold-text pl-1">Este campo es obligatorio ...</p>
</template>

<script>
    import store from "@/store";

    export default {
        name: 'CheckAviso',
        data() {
            return {
                aviso: false
            }
        },
        props:{
            errorFlag: Boolean
        },
        store,
        watch: {
            'aviso': function(value) {
                // Actualiza en el store el valor del checkbox
                store.commit('setLegalAdvise', value)
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/variables.scss";
    .checkbox {
        width: 1.6em;
        height: 1.6em;
        background: #fff;
        border-radius: 50%;
        vertical-align: middle;
        border: 2px solid $blue;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        &:checked{
            background-image: url("../../../assets/img/checked_icon.svg");
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .bold-text{
        font-family: 'Montserrat-Bold';
    }

    .text-blue{
        a{
            color: $blue;    
        }
        color: $blue!important;
    }
</style>