<template>
  <section id="documentacionNecesaria" class="pt-4 pb-2" :class="step == 6 ? 'step6' : ''">
    <div v-if="formulario.step == 6" class="bg d-flex row justify-content-center align-items-center">
      <div class="container">
        <h4 class="text-center text-white franja-azul ">
          Préstamo preaprobado <br class="d-none d-sm-none d-md-block d-lg-block d-xl-none"> de {{ "$" + formatNumber(max_prestamo) }} 
        </h4>
      </div>
    </div>
    <div v-if="formulario.step >= 7" class="bg d-flex row justify-content-center align-items-center">
      <div class="container">
        <h4 class="text-center text-white franja-azul">
          Préstamo preaprobado <br class="d-block d-sm-none d-md-block d-lg-block d-xl-none"> de {{ "$" + formatNumber(max_prestamo) }} 
        </h4>
      </div>
    </div>

    <div v-if="formulario.step >= 7" class="d-flex row justify-content-center align-items-center">
      <div class="col-10 col-sm-7 col-md-7 col-lg-7 col-xl-12">
          <div class="text-center pb-4">
            <img width="115" height="115" src="@/assets/img/ok.svg" alt="circle_check_green" class="mt-5" />
          </div>
          <div>
            <p class="text-center font-weight-bold text-blue d-block d-sm-block d-md-block d-lg-block d-xl-block">
              Muchas gracias<br> La documentación se ha recibido correctamente.
            </p>
            <p class="text-center font-weight-bold text-blue d-block d-sm-block d-md-block d-lg-block d-xl-block">
              Un operador de Ibancar se pondrá en contacto contigo para su validación 
            </p>
            <p class="pt-5 pb-3 text-center font-weight-bold d-block d-sm-block d-md-block d-lg-block d-xl-block" style="font-family: 'Montserrat-Medium';">
              Si deseas agilizar tu solicitud, contáctenos
            </p>
          </div>
      </div>
      <div class="row justify-content-center text-center pb-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-12">
          <div class="card-columns pt-0 pl-4 pr-4 pb-4">
            <div class="card" @click="openWhatsapp()">
              <div class="row align-items-center">
                <div class="col-3 pt-3 pb-3">
                  <img src="../../assets/img/whatsapp.svg" alt="dinero" />
                </div>
                <div class="col-9 text-left">
                  <span class="font-weight-bold">WhatsApp</span>
                </div>
              </div>
            </div>
            <div class="card pe-clickable" @click='openLlamada()'>
            <a href="tel:5555661583">
              <div class="row align-items-center">
                <div class="col-3 pt-3 pb-3">
                  <img src="../../assets/img/phone.svg" alt="phone" />
                </div>
                <div class="col-9 text-left">
                  <span class="font-weight-bold text-dark" >Llamar por teléfono</span>
                </div>
              </div>
            </a>
          </div>
          </div>
        </div>
      </div>
      
    </div>

    <div v-else class="row justify-content-center">
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 col-xxl-4 pl-1 pr-1 text-center">
        <div id="accordion">
          <div class="card" style="border: none; box-shadow: none;">
            <div v-if="formulario.step != 6" class="card-header" id="headingOne">
              <h5 id="arrow_title" class="mb-0">
                <button id="arrow_button" class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="false" aria-controls="collapseOne">
                  <div class="d-flex flex-row justify-content-between align-items-center">
                    <span class="text-blue">¿Qué documentación enviar?
                      <img v-if="!arrow" width="19" height="12"
                        class="icon-fluid pl-2" src="./../../assets/img/bluearrowup.svg" alt="ibancar" />
                      <img v-else width="19" height="12" class="icon-fluid pl-2"
                        src="./../../assets/img/bluearrowdown.svg" alt="ibancar" /></span>
                  </div>
                </button>
              </h5>
            </div>

            <div v-if="formulario.step == 6" class="d-flex row justify-content-center align-items-center">
              <div class="container p-4">
                <div class="row justify-content-center">
                </div>
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12">
                    <p class="text-center font-weight-bold text-blue d-block d-sm-block d-md-block d-lg-none d-xl-none">
                      En esta sección puede subir la documentación, un agente te
                      contactará para aclarar cualquier duda.
                    </p>
                    <p class="text-center font-weight-bold text-blue d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      En esta sección puede subir la documentación, un agente te
                      contactará para aclarar cualquier duda.
                    </p>
                    <a href="#newStepDoc" class="center btn-outline-documentacion font-weight-bold text-blue">Subir&nbsp;documentación</a>
                  </div>
                </div>
              </div>
            </div>
            <div id="collapseOne" :class="'collapse show'" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-6">
                  <div class="card-body">
                    <div class="row justify-content-center">
                      <div class="col-lg-10 col-xl-10">
                        <ul class="text-left lista">
                          <h2 class="text-left font-weight-bolder cuarta mb-4">
                            <strong>Documentación personal:</strong>
                          </h2>
                          <li>
                            <a href="#" @click="openModal('modal_dni', 'Parte delantera y trasera del INE', ['ine2.png','ine1.png'])"  data-toggle="modal" :data-target="'#'+nombre_modal">
                              INE escaneada por ambos lados.
                            </a>
                          </li>
                          <li class="d-none">
                            <a href="" @click="openModal('imagen_selfie', ' Foto Selfie con INE ', ['inelanding.png'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Selfie con INE.</a>
                          </li>
                          <li>
                            <a href="" @click="openModal('modal_justificante_ingresos', 'Nómina del último mes', ['nomina.png'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Comprobante de ingresos (último recibo de nómina o 2 estados de cuenta).</a>
                          </li>
                          <li class="d-none">
                            <a href="" @click="openModal('modal_justificante_ingresos', 'Carátula bancaria', ['caratula.png'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Carátula bancaria del cliente.</a>
                          </li>
                          <li>
                            <a href="" @click="openModal('modal_justificante_ingresos', 'Documentos validos: Recibos de Luz, Agua, Gas, Internet, Telefono, Impuesto predial, Estado de cuenta Bancario', ['comprobantes.png'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Comprobante de domicilio (con una antigüedad máxima de 3 meses).</a>
                          </li>  
                          <li class="d-none">
                            <a href="" @click="openModal('modal_constancia_fiscal', 'Constancia de situación fiscal', ['contanciafiscal.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Constancia de situación fiscal.</a>
                          </li>   
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-6">
                  <div class="card-body">
                    <div class="row justify-content-center">
                      <div class="col-lg-10 col-xl-10">
                        <ul class="text-left lista">
                          <h2 class="text-left font-weight-bolder cuarta mb-4">
                            <strong>Documentación del vehículo:</strong>
                          </h2>
                          <li>
                            <a href="#" @click="openModal('factura_original', 'Factura original', ['factura.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Fotografía de factura original del coche por ambos lados.</a>
                          </li>
                          <li>
                            <a href="#" @click="openModal('permiso_circulacion', 'Tarjeta de circulación', ['circulacion.jpg'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Fotografía de tarjeta de circulación.</a>
                          </li>
                          <li>
                            <a href="#" @click="openModal('fotos', 'Fotografías del coche', ['4fotos.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">1 Fotografía del coche.</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="newStepDoc"></div>
              <div class="row justify-content-center">
                <NuevoStepDoc v-if="formulario.step == 6" :solicitud="solicitud" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
    <div>
      <!-- Modal confirmar reinicio solicitud -->
      <button id="btn_modal_reiniciar_solicitud" class="d-none" data-toggle="modal"
        data-target="#modalReiniciarSolicitud"></button>
      <div id="modalReiniciarSolicitud" class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog"
        aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div id="modal-reiniciar" class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="d-flex row justify-content-center align-items-center m-3">
              <div class="col-12">
                <p class="center text-center fs-18">
                  ¿Estás seguro de querer eliminar tu solicitud de préstamo?
                </p>
              </div>
              <div class="col-6 d-flex row justify-content-center align-items-center">
                <button class="btn btn-cancelar" data-dismiss="modal">
                  No reiniciar
                </button>
              </div>
              <div class="col-6 d-flex row justify-content-center align-items-center">
                <button @click="reiniciarSolicitud()" class="btn btn-eliminar" data-dismiss="modal">
                  Reiniciar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Slogan />
        <div>
            <ModalGenerica :modalName="nombre_modal" :title="title" :img="img_url" />
        </div>
  </section>
  <!-- <BotonVolver></BotonVolver> -->
</template>

<script>
import ModalGenerica from '@/components/ComoFunciona/ModalGenerica.vue';
import NuevoStepDoc from "@/components/FormularioMexico/NuevoStepDoc.vue";
import Slogan from '@/components/Slogan.vue';
import store from "@/store";
import axios from "axios";
import { mapState } from "vuex";
// import BotonVolver from "./BotonVolver.vue";

export default {
  name: "NuevoSectionDocumentacion",
  components: {
    NuevoStepDoc,
    ModalGenerica,
    Slogan,
    // BotonVolver
  },
  props: {},
  data() {
    return {
      arrow: false,
      nombre_modal: '',
      title: '',
      img_url: null
    };
  },
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    max_prestamo: (state) => state.max_prestamo,
  }),

  mounted() {
    this.interval = setInterval(this.updateArrow, 100); //AZTUALIZAMOS LA DIRECION DE LA FLECHA CADA 100 TICKS
  },
  methods: {
    openWhatsapp: function () {
      window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

      window.dataLayer.push({
        event: "click_whatsapp",
      });
      window.open("https://api.whatsapp.com/send?l=es&phone=5215612673878&text=%F0%9F%91%8B%20%C2%A1Hola!%20He%20terminado%20de%20subir%20documentos%20y%20quiero%20continuar%20mi%20solicitud%20de%20pr%C3%A9stamo%20por%20WhatsApp.%20Muchas%20gracias.", "_blank");
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    back() {
      this.$router.go(-1);
    },
    updateArrow() {
      var el = document.getElementById("collapseOne");
      if (el) this.arrow = el.classList.contains("show");
      else this.arrow = false;
    },
    goTo(route,hash) {
      this.$router.push({path: route, hash: hash});
    },
    openLlamada: function () {
      window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

      window.dataLayer.push({
        event: "click_telefono",
      });
    },
    openModal(nombre, title, img_url) {
      this.nombre_modal = nombre;
      this.title = title;
      this.img_url = img_url;
    },
    openModalConfirmarReinicio() {
      document.getElementById("btn_modal_reiniciar_solicitud").click();
    },
    reiniciarSolicitud() {
      // Buscar id solicitud
      var idSolicitud = this.solicitud.id;
      axios
        .post(
          process.env.VUE_APP_AXIOS_BASE_URL +
          "reiniciar/solicitud/" +
          idSolicitud
        )
        .then((resp) => {
          let bool = resp.data;
          if (bool == true) {
            store.commit("resetSolicitud");
            this.$router.push({ path: "/", hash: null });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
a{
  color:black;
}
.card {
    background-color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
.card-header {
  border: none;
  background-color: #fff;
}

.lista {
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    text-indent: -1.5em;
    list-style-type: none;
    line-height: 1.9em;
  }

  li:before {
    content: url("../../assets/img/green_check.svg");
    padding-right: 10px;
  }
}

.text-blue {
  color: #004680;
  font-family: "Montserrat-Bold";
}

.pe-clickable {
  color: #c4c4c4 !important;
}
.bg {
  height: 13vh !important;
}
.franja-azul {
  font-family: 'Montserrat';
  font-weight: 800;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.btn-outline-documentacion {
    color: $white !important;
    border-color: #004680;
    background-color: #004680;
    border-radius: 20px;
    padding: 10px 40px;
    width: 90%;
}

@include media-breakpoint-only(xs) {
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 350px;
    font-family: 'Montserrat-Medium';
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
  
  .bg {
    margin-top: 10%;
  }

  h2 {
    font-size: 1.25rem;
  }

  .cuarta {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  .btn-outline-documentacion {
    display: block !important;
  }
}

@include media-breakpoint-only(sm) {
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 100%;
    font-family: 'Montserrat-Medium';
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
  .bg {
    margin-top: 83px;
  }

  h2 {
    font-size: 1.25rem;
  }

  .cuarta {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  .btn-outline-documentacion {
    display: none !important;
  }
}

@include media-breakpoint-only(md) {
  .card-columns {
    column-count: 2;
    max-width: 100%;
    width: 100%;
    font-family: 'Montserrat-Medium';
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
  .bg {
    margin-top: 73px;
  }

  h2 {
    font-size: 1.25rem;
  }

  .cuarta {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  .fs-18 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .btn-outline-documentacion {
    display: none !important;
  }

}

@include media-breakpoint-only(lg) {
  .card-columns {
    column-count: 2;
    max-width: 100%;
    width: 100%;
    font-family: 'Montserrat-Medium';
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
  .bg {
    margin-top: 73px;
  }

  h2 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 2rem;
  }

  p {
    font-size: 1.4rem;
  }

  .fs-18 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .btn-outline-documentacion {
    display: none !important;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .card-columns {
    column-count: 2;
    max-width: 100%;
    width: 100%;
    font-family: 'Montserrat-Medium';
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
  .bg {
    margin-top: 123px;
  }

  h2 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 2.4rem;
  }

  .text-blue {
    font-size: 1.2rem;
  }

  .fs-18 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .btn-outline-documentacion {
    display: none !important;
  }

}
</style>
