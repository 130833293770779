<template>
    <!-- Elemento reutilizable que tiene el input del CP y además agrega el estado al que pertenece -->
    <div class="row mx-0 shared-input-container" :class="{ 'is-invalid error': errorFlag || (estado == null && value != null && value != '') }">
        <div class="col-5">
            <input  type="text" class="form-control custom-input shared-input" placeholder="Código postal" max="99999" maxlength="5" v-model="value"/>
        </div>
        <div class="col-7 border-left">
            <!-- Muestra el estado al que está relacionado este CP -->
            <input disabled type="text" name="estado" class="form-control shared-input disabled-input" placeholder="" v-model="estado" />
        </div>
    </div>
    <!-- Mensaje de error -->
    <p v-if="errorFlag  || (estado == null && value != null && value != '')" class="text-danger mb-0" :class="errorTextClass">Ingresa un código postal válido ...</p>
</template>

<script>
    import { mapState } from "vuex";
    import store from "@/store";
    import axios from "axios";

    // Regex a usar que valida el ingreso de solamente dígitos
    const numberRegex = /^[0-9]*$/;

    export default {
        name: 'InputCP',
        data() {
            return {
                value: null,
                estado: null,
            }
        },
        store,
        computed: mapState({
            cp: (state) => state?.formulario?.codigo_postal
        }),
        props: {
            errorFlag: Boolean,
            errorTextClass: String || null
        },
        methods: {
            validateCp: async function(value){
                // Para validar CP, manda el estado a null
                this.estado = null;
                // Valida que CP tenga un dato, no sea diferente a 5 y pase el regex
                if(!(!value ||  value.length !== 5 || !numberRegex.test(value))){
                    // Llamada a la API que confirma el CP y obtiene el estado
                    await this.confirmCP()
                }
            },
            confirmCP: function(){
                // Manda estado a null
                this.estado = null;
                axios.get(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/validate-cp/${this.value}`).then(
                    (response) => {
                        const { error, cp } = response.data
                        if(error){
                            // En caso de error, el estado se mantiene en null
                            this.estado = null;
                        }else{
                            // Si la API no fallo, entonces se obtiene el estaod
                            const { estado } = cp;
                            this.estado = estado;
                        }
                    }
                ).catch(() => {
                            // En caso de error, el estado se mantiene en null
                    this.estado = null;
                })
            },
        },
        watch: {
            'value': async function(value, oldVal){
                const flag = numberRegex.test(value)
                if(flag){
                    this.validateCp(value)
                }else{
                    // Si detecta algo que no sea número, entonces no actualiza el valor del CP
                    this.value = oldVal
                }
            },
            'estado': async function(value){
                // Si detecta un cambio en el estado, entonces se actualiza el valor del store para CP y estado
                store.commit('setCodigoPostal', this.value);
                store.commit('setEstado', value);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";

    .shared-input{
        border: none!important;
        border-radius: 0!important;
        min-height: 48px;
        &:focus-visible{
            outline: none;
        }
    }

    .disabled-input{
        background-color: unset;
    }

    .shared-input-container{
        border-radius: 10px;
        border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.4));
        overflow: hidden;
        &:focus-within{
            border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
            outline: -webkit-focus-ring-color auto 1px;
            &.is-invalid{
                outline: 2px solid $red-2;
            }
        }
        &.is-invalid{
            border-color: #dc3545;
            .disabled-input{
                background-color: transparentize($red-2, .8);
            }
        }
        &.success{
            border: 2px solid $blue;
            color: $blue;
        }
    }

    .bold-text{
        font-family: 'Montserrat-Bold';
    }

    .remove-margin{
        margin-left: -1.5rem;
    }
</style>