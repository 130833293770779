<template>
    <!-- Componente reutilizable con checkbox de comunicación -->
    <div class="row mx-0">
        <div class="col-1 pt-4 text-center">
            <input type="checkbox" name="informacion" class="checkbox" v-model="informacion" required/>
        </div>
        <div class="col-11 pt-4 pl-2">
            <label class="checkbox_label mb-0" for="informacion">
                <span class="checkbox_text">
                    Acepto recibir información de <strong class="text-blue bold-text">IBANCAR LATAM S.A. de C.V. </strong>
                    <span v-if="leerMas">que, según lo indicado en el 
                        <router-link to="/politica-privacidad" target="_blank">Aviso de Privacidad</router-link>
                        , consideren pertinente enviarme por correo electrónico y por WhatsApp. (Existe la opción de darme de baja en cualquier momento) 
                    </span>
                </span>
            </label>
            <!-- Texto que muestra texto mostrar u ocultar información extra -->
            <small @click="leerMas = !leerMas" class="verMas pb-1">
                {{ leerMas ? "Leer menos" : "Leer mas" }}
            </small>
        </div>
    </div>
    <!-- Mensaje de error -->
    <p v-if="errorFlag" class="text-danger mt-1 mb-0 bold-text pl-1">Este campo es obligatorio ...</p>
</template>

<script>
    import store from "@/store";

    export default {
        name: 'CheckInformacion',
        data() {
            return {
                informacion: false,
                leerMas: false
            }
        },
        props: {
            errorFlag: Boolean
        },
        store,
        watch: {
            'informacion': function(value) {
                // Actualiza en el store el valor del checkbox
                store.commit('setLopd', value)
            }
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
    .checkbox {
        width: 1.6em;
        height: 1.6em;
        background: #fff;
        border-radius: 50%;
        vertical-align: middle;
        border: 2px solid $blue;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        &:checked{
            background-image: url("../../../assets/img/checked_icon.svg");
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .verMas {
        color: #ffa500;
        font-weight: 600;
        cursor: pointer;
    }

    .bold-text{
        font-family: 'Montserrat-Bold';
    }

    .text-blue{
        color: $blue;
    }
</style>